import { Route, Switch } from 'react-router-dom';
import React from 'react';
import Help from '../../shared/components/custom/Help';
import ClubAdminCreatePage from '../club_admin/ClubAdminCreatePage';
import SeasonCreatePage from '../season/SeasonCreatePage';
import SeasonEditPage from '../season/SeasonEditPage';
import SeasonsPage from '../season/SeasonsPage';
import NewsFormPage from '../news/NewsFormPage';
import NewsAPIFormPage from '../news/NewsAPIFormPage';
import NewsPage from '../news/NewsPage';
import CreateUserPage from '../users/CreateUserPage';
import EditUserPage from '../users/EditUserPage';
import UsersPage from '../users/UsersPage';
import CompanyFormPage from '../companies/CompanyFormPage';
import CompanyListPage from '../companies/CompanyListPage';
import DealsListPage from '../deals/DealsListPage';
import MatchFormPage from '../matches/MatchFormPage';
import MatchesPage from '../matches/MatchesPage';
import AssignSeasonTicketPage from '../tickets/screens/season_tickets/screens/assign';
import EditSeasonTicketPage from '../tickets/screens/season_tickets/screens/edit/EditSeasonTicketPage';
import AssignMatchTicketPage from '../tickets/screens/match_tickets/screens/assign';
import EditMatchTicketPage from '../tickets/screens/match_tickets/screens/edit/EditMatchTicket';
import TicketsPage from '../tickets/TicketsPage';
import VolumeDiscountFormPage from '../discounts/VolumeDiscountFormPage';
import DiscountCodeFormPage from '../discounts/DiscountCodeFormPage';
import DiscountsPage from '../discounts/DiscountsPage';
import ProductFormPage from '../click_and_collect/screens/product/ProductFormPage';
import CategoryCreatePage from '../click_and_collect/screens/category/CategoryCreatePage';
import CategoryEditPage from '../click_and_collect/screens/category/CategoryEditPage';
import KioskFormPage from '../click_and_collect/KioskFormPage';
import ClickAndCollectPage from '../click_and_collect';
import PushData from '../messages/PushData';
import MessagesPage from '../messages/MessagesPage';
import CreateTeamPage from '../settings/CreateTeamPage';
import EditTeamPage from '../settings/EditTeamPage';
import PriceGroupsFormPage from '../settings/PriceGroupsFormPage';
import MatchTypeFormPage from '../settings/MatchTypeFormPage';
import TermFormPage from '../settings/TermFormPage';
import ControlImageFormPage from '../settings/ControlImageFormPage';
import SettingsPage from '../settings/SettingsPage';
import LayoutPage from '../app_layout/LayoutPage';
import BoxOfficePage from '../box_office/BoxOfficePage';
import KioskPage from '../kiosk/KioskPage';
import CreateFieldTypePage from '../stadium/CreateFieldTypePage';
import EditFieldTypePage from '../stadium/EditFieldTypePage';
import FieldFormPage from '../stadium/FieldFormPage';
import StadiumPage from '../stadium/StadiumPage';
import LotteryFormPage from '../lottery/LotteryFormPage';
import LotteryPage from '../lottery/LotteryPage';
import ReportsPage from '../reports/ReportsPage';
import PackagesFormPage from '../packages/PackagesFormPage';
import PackagesPage from '../packages/PackagesPage';
import BalancePackageFormPage from '../balance_packages/BalancePackageFormPage';
import BalancePackagesPage from '../balance_packages/BalancePackagesPage';
import PlayerFormPage from '../players/PlayerFormPage';
import PlayersListPage from '../players/PlayersListPage';
import AuctionItemFormPage from '../auctions/AuctionItemFormPage';
import AuctionHouseFormPage from '../auctions/AuctionHouseFormPage';
import AuctionHouseListPage from '../auctions/AuctionHouseListPage';
import QuestionairePage from '../questionaires/QuestionairePage';
import ExternalStoreFormPage from '../external_store/ExternalStoreFormPage';
import ExternalStorePage from '../external_store/ExternalStorePage';
import SpotlightGroupFormPage from '../Spotlight/SpotlightGroupFormPage';
import SpotlightSegmentFormPage from '../users/SpotlightSegmentFormPage';
import SpotlightPage from '../Spotlight/SpotlightPage';
import EventsFormPage from '../events/EventsFormPage';
import EventsClonePage from '../events/EventsClonePage';
import EventsPage from '../events/EventsPage';
import UserTiersFormPage from '../user_tiers/UserTiersFormPage';
import UserTiersPage from '../user_tiers/UserTiersPage';
import DashboardPage from '../dashboard/DashboardPage';
import NotFoundPage from './NotFoundPage';
import CreateVendor from '../vendor/CreateVendor';
import ListVendors from '../vendor/ListVendors';
import ClubAdminTabs from '../club_admin/ClubAdminTabs';
import TeamAdminCreatePage from '../club_admin/TeamAdminCreatePage';
import LeagueAdminCreatePage from '../club_admin/LeagueAdminCreatePage';
import MatchAdminCreatePage from '../club_admin/MatchAdminCreatePage';
import SeasonAdminCreate from '../club_admin/SeasonAdminCreate';
import ClubProfilePage from '../settings/ClubProfilePage';
import OffersPage from '../offers/OffersPage';
import OfferFormPage from '../offers/OfferFormPage';
import ExternalTicketsPage from '../tickets/ExternalTicketsPage';
import SpotlightTileFormPage from '../Spotlight/SpotlightTileFormPage';
import NewsCategoryFormPage from '../news/NewsCategoryFormPage';
import NewsProviderFormPage from '../news/NewsProviderFormPage';
import UpcomingMatches from '../match_center/Upcoming';
import UpcomigMatchFormPage from '../match_center/UpcomigMatchFormPage';
import FanRating from '../match_center/FanRating';
import ContactSalesPage from '../shared/ContactSalesPage';
import PrizeFormPage from '../match_center/components/PrizeFormPage';
import MessagesAPIFormPage from '../messages/MessagesAPIFormPage';
import MessagesFormPage from '../messages/MessagesFormPage';
import CommunicationApiStatsPage from '../messages/CommunicationApiStatsPage';
import QuestionaireFormPage from '../questionaires/QuestionaireFormPage';
import QuestionFormPage from '../questionaires/QuestionFormPage';
import NotificationLabelFormPage from '../messages/NotificationLabelFormPage';

const Pages = () => {
  // ~/link/subLink must be above ~/link due to switch. It gets the first that matches
  return (
    <>
      <Help />
      <Switch>
        <Route path="/vendors/edit/:vendorId" component={CreateVendor} />
        <Route path="/vendors/create" component={CreateVendor} />
        <Route path="/vendors" component={ListVendors} />
        <Route path="/clubadmin/matches/edit/:matchId" component={MatchAdminCreatePage} />
        <Route path="/clubadmin/team/edit/:teamId" component={TeamAdminCreatePage} />
        <Route path="/clubadmin/league/create" component={LeagueAdminCreatePage} />
        <Route path="/clubadmin/league/edit/:leagueId" component={LeagueAdminCreatePage} />
        <Route path="/clubadmin/season/create" component={SeasonAdminCreate} />
        <Route path="/clubadmin/season/edit/:seasonId" component={SeasonAdminCreate} />
        <Route path="/clubadmin/create" component={ClubAdminCreatePage} />
        <Route path="/clubadmin/edit/:clubId" component={ClubAdminCreatePage} />
        <Route path="/clubadmin" component={ClubAdminTabs} />
        <Route path="/seasons/create" component={SeasonCreatePage} />
        <Route path="/seasons/edit/:seasonID" component={SeasonEditPage} />
        <Route path="/seasons" component={SeasonsPage} />
        <Route path="/sponsor_news/create" component={NewsFormPage} />
        <Route path="/sponsor_news/edit/:newsID" component={NewsFormPage} />
        <Route path="/news/create" component={NewsFormPage} />
        <Route path="/news/edit/:newsID" component={NewsFormPage} />
        <Route path="/news" component={NewsPage} />
        <Route path="/news_api/create" component={NewsAPIFormPage} />
        <Route path="/news_providers/edit/:providerID" component={NewsProviderFormPage} />
        <Route path="/news_category/create" component={NewsCategoryFormPage} />
        <Route path="/news_category/edit/:categoryID" component={NewsCategoryFormPage} />
        <Route path="/users/create" component={CreateUserPage} />
        <Route path="/users/edit/:userID" component={EditUserPage} />
        <Route path="/users/segment/create" component={SpotlightSegmentFormPage} />
        <Route path="/users/segment/edit/:entityID" component={SpotlightSegmentFormPage} />
        <Route path="/users" component={UsersPage} />
        <Route path="/deals" component={DealsListPage} />
        <Route path="/companies/create" component={CompanyFormPage} />
        <Route path="/companies/edit/:companyID" component={CompanyFormPage} />
        <Route path="/companies" component={CompanyListPage} />
        <Route path="/matches/create/:seasonId" component={MatchFormPage} />
        <Route path="/matches/edit/:matchID" component={MatchFormPage} />
        <Route path="/matches/upcoming" exact component={UpcomingMatches} />
        <Route path="/matches/upcoming/custom/create" exact component={UpcomigMatchFormPage} />
        <Route path="/matches/upcoming/custom/edit/:matchID" exact component={UpcomigMatchFormPage} />
        <Route path="/matches/upcoming/edit/:matchID" exact component={UpcomigMatchFormPage} />
        <Route path="/matches/upcoming/fanrating/:matchID" exact component={FanRating} />
        <Route path="/matches" component={MatchesPage} />
        <Route path="/tickets/external" exact component={ExternalTicketsPage} />
        <Route path="/tickets/season/assign/:seatID" component={AssignSeasonTicketPage} />
        <Route path="/tickets/season/edit/:seasonTicketID" component={EditSeasonTicketPage} />
        <Route path="/tickets/match/assign/:matchID/:seatID" component={AssignMatchTicketPage} />
        <Route path="/tickets/match/edit/:id" component={EditMatchTicketPage} />
        <Route path="/tickets" component={TicketsPage} />
        <Route path="/discounts/volume/create" component={VolumeDiscountFormPage} />
        <Route path="/discounts/volume/edit/:volumeDiscountId" component={VolumeDiscountFormPage} />
        <Route path="/discounts/code/create" component={DiscountCodeFormPage} />
        <Route path="/discounts/code/edit/:discountCodeId" component={DiscountCodeFormPage} />
        <Route path="/discounts" component={DiscountsPage} />
        <Route path="/cnc/products/create" component={ProductFormPage} />
        <Route path="/cnc/products/edit/:kioskID" component={ProductFormPage} />
        <Route path="/cnc/categories/create" component={CategoryCreatePage} />
        <Route path="/cnc/categories/edit/:categoryID" component={CategoryEditPage} />
        <Route path="/cnc/create" component={KioskFormPage} />
        <Route path="/cnc/edit/:kioskID" component={KioskFormPage} />
        <Route path="/cnc" component={ClickAndCollectPage} />
        <Route path="/messages/insight/:pushID" component={PushData} />
        <Route path="/messages/edit/:messageId" component={MessagesFormPage} />
        <Route path="/messages" component={MessagesPage} />
        <Route path="/messages_api/create" component={MessagesAPIFormPage} />
        <Route path="/messages_api/show/:accessId" component={CommunicationApiStatsPage} />
        <Route path="/notification_label/create" component={NotificationLabelFormPage} />
        <Route path="/notification_label/edit/:labelId" component={NotificationLabelFormPage} />
        <Route path="/settings/team/create" component={CreateTeamPage} />
        <Route path="/settings/team/edit/:teamID" component={EditTeamPage} />
        <Route path="/settings/price_group/create" component={PriceGroupsFormPage} />
        <Route path="/settings/price_group/edit/:priceGroupID" component={PriceGroupsFormPage} />
        <Route path="/settings/match_type/create" component={MatchTypeFormPage} />
        <Route path="/settings/match_type/edit/:matchID" component={MatchTypeFormPage} />
        <Route path="/settings/terms/create" component={TermFormPage} />
        <Route path="/settings/terms/edit/:termID" component={TermFormPage} />
        <Route path="/settings/control_images/create" component={ControlImageFormPage} />
        <Route path="/settings/control_images/edit/:controlImageID" component={ControlImageFormPage} />
        <Route path="/settings" component={SettingsPage} />
        <Route path="/app_layout" component={LayoutPage} />
        <Route path="/box" component={BoxOfficePage} />
        <Route path="/kiosk" component={KioskPage} />
        <Route path="/stadiums/field_types/create" component={CreateFieldTypePage} />
        <Route path="/stadiums/field_types/edit/:fieldTypeID" component={EditFieldTypePage} />
        <Route path="/stadiums/fields/create" component={FieldFormPage} />
        <Route path="/stadiums/fields/edit/:fieldID" component={FieldFormPage} />
        <Route path="/stadiums" component={StadiumPage} />
        <Route path="/lottery/create" component={LotteryFormPage} />
        <Route path="/lottery/edit/:lotteryID" component={LotteryFormPage} />
        <Route path="/lottery" component={LotteryPage} />
        <Route path="/reports" component={ReportsPage} />
        <Route path="/packages/create" component={PackagesFormPage} />
        <Route path="/packages/edit/:packageID" component={PackagesFormPage} />
        <Route path="/packages" component={PackagesPage} />
        <Route path="/balances/create" component={BalancePackageFormPage} />
        <Route path="/balances/edit/:packageID" component={BalancePackageFormPage} />
        <Route path="/balances" component={BalancePackagesPage} />
        <Route path="/players/:teamId/create" component={PlayerFormPage} />
        <Route path="/players/:teamId/edit/:playerID" component={PlayerFormPage} />
        <Route path="/players/:teamId?" component={PlayersListPage} />
        <Route path="/profile" component={ClubProfilePage} />
        <Route path="/auctions/edit/:auctionHouseId/create" component={AuctionItemFormPage} />
        <Route path="/auctions/edit/:auctionHouseId/edit/:auctionItemId" component={AuctionItemFormPage} />
        <Route path="/auctions/create" component={AuctionHouseFormPage} />
        <Route path="/auctions/edit/:auctionID" component={AuctionHouseFormPage} />
        <Route path="/auctions" component={AuctionHouseListPage} />
        <Route path="/questionnaires/create" component={QuestionaireFormPage} />
        <Route path="/questionnaires/clone/:questionaireID" component={QuestionaireFormPage} />
        <Route path="/questionnaires/edit/:questionaireID" component={QuestionaireFormPage} />
        <Route path="/questionnaires/questions/create" component={QuestionFormPage} />
        <Route path="/questionnaires/questions/edit/:questionID" component={QuestionFormPage} />
        <Route path="/questionnaires" component={QuestionairePage} />
        <Route path="/external_stores/create" component={ExternalStoreFormPage} />
        <Route path="/external_stores/edit/:externalStoreID" component={ExternalStoreFormPage} />
        <Route path="/external_stores" component={ExternalStorePage} />
        <Route path="/spotlight/group/edit/:groupID/tile/create" component={SpotlightTileFormPage} />
        <Route path="/spotlight/group/edit/:groupID/tile/edit/:tileID" component={SpotlightTileFormPage} />
        <Route path="/spotlight/group/create" component={SpotlightGroupFormPage} />
        <Route path="/spotlight/group/edit/:entityID" component={SpotlightGroupFormPage} />
        <Route path="/prizes/create" component={PrizeFormPage} />
        <Route path="/prizes/edit/:entityID" component={PrizeFormPage} />
        <Route path="/spotlight" component={SpotlightPage} />
        <Route path="/events/create" component={EventsFormPage} />
        <Route path="/events/edit/:eventID" component={EventsFormPage} />
        <Route path="/events/clone/:eventID" component={EventsClonePage} />
        <Route path="/events" component={EventsPage} />
        <Route path="/offers/create" component={OfferFormPage} />
        <Route path="/offers/clone/:offerID" component={OfferFormPage} />
        <Route path="/offers/edit/:offerID" component={OfferFormPage} />
        <Route path="/offers" component={OffersPage} />
        <Route path="/user_tiers/edit/:tierID" component={UserTiersFormPage} />
        <Route path="/user_tiers/create" component={UserTiersFormPage} />
        <Route path="/user_tiers" component={UserTiersPage} />
        <Route path="/user_tiers" component={UserTiersPage} />
        <Route path="/contact-sales" component={ContactSalesPage} />
        <Route exact path="/" component={DashboardPage} />
        <Route component={NotFoundPage} />
      </Switch>
    </>
  );
};

export default Pages;
