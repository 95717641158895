import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import {
  Col,
  Container,
  Row,
  CardBody,
  Card,
  TabContent,
  TabPane,
  Badge,
} from 'reactstrap';
import moment from 'moment';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import { withRouter, useHistory } from 'react-router-dom';
import fileDownload from 'js-file-download';
import Api from '../../util/api';
import exportToExcelFile from '../../util/xlsx';
import EventsForm from './components/EventsForm';
import Loading from '../../shared/components/custom/Loading';
import NavTab from '../../shared/components/custom/NavTab';
import EventsContactForm from './components/EventsContactForm';
import t, { partial } from '../../util/translation/translation';
import EventsPariticipantForm from './components/EventsParticipantForm';
import { isValidUrl } from '../../util/functions';
import QuestionCard from './components/QuestionCard';
import { EventState } from './context';
import useConfig from '../../util/useConfig';
import EventTypes from './types';
import AlertModal, {
  AlertModalType,
} from '../../shared/components/custom/AlertModal';
import LazyTabPane from '../../shared/components/custom/LazyTabPane';
import WaitlistPage from './components/WaitlistPage';

const EventsFormPage = ({ eventForm = {} }) => {
  const [tab, setTab] = useState('');
  const [event, setEvent] = useState(null);
  const [loading, setLoading] = useState(false);
  const [eventCategories, setEventCategories] = useState([]);
  const [eventLevels, setEventLevels] = useState([]);
  const [eventGroups, setEventGroups] = useState([]);
  const [participants, setParticipants] = useState([]);
  const [deletedParticipants, setDeletedParticipants] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [inviteModelVisible, setInviteModelVisible] = useState(false);
  const [scheduleMessageID, setScheduleMessageID] = useState(null);
  const [scheduleMessagesForEvent, setScheduleMessagesForEvent] = useState([]);
  const [numberOfPendingMessagesPerEvent, setNumberOfPendingMessagesPerEvent] =
    useState([]);
  const [totalAmountOfScheduledMessages, setTotalAmountOfScheduledMessages] =
    useState(0);
  const [messageStatus, setMessageStatus] = useState('Scheduled');
  const [clearContactPage, setClearContactPage] = useState(false);
  const [startDate, setStartDate] = useState();
  const [questionsNanswers, setQuestionsNansewrs] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [userAnswers, setUserAnswers] = useState([]);
  const config = useConfig();
  const [eventTiers, setEventTiers] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [showPermissionModal, setShowPermissionModal] = useState(false);
  const [maxGuestAmount, setMaxGuestAmount] = useState(0);
  const [currentParticipant, setCurrentParticipant] = useState(null);
  const { eventID } = useParams();
  const history = useHistory();
  const p = partial('EventsFormPage');
  const f = partial('OfferForm');
  const getData = async () => {
    setLoading(true);
    try {
      const [
        resEvent,
        resEventCategories,
        resEventLevels,
        resEventGroups,
        resTiers,
      ] = await Promise.all([
        eventID && Api.events.show(eventID),
        Api.events.getEventCategories(),
        Api.club.getLevels(),
        Api.club.getGroups(),
        Api.events.fetchTiers(config),
      ]);
      setEventCategories(
        resEventCategories.data.map((category) => {
          const { id, title, maxAttendees } = category;
          return {
            value: id,
            label: title,
            maximum: maxAttendees,
          };
        }),
      );
      setEventLevels(
        resEventLevels.map((level) => {
          const { id, value } = level;
          return {
            value: id,
            label: value,
          };
        }),
      );

      setEventGroups(
        resEventGroups.map((group) => {
          const { id, value } = group;
          return {
            value: id,
            label: value,
          };
        }),
      );

      setEventTiers(resTiers);

      setLoading(false);
      if (eventID) {
        setEvent(resEvent.data);
      }
    } catch (err) {
      toast.error(err);
      setLoading(false);
    }
  };
  const getEvent = async () => {
    setLoading(true);
    try {
      const resEvent = await Api.events.show(eventID);
      setEvent(resEvent.data);
      setLoading(false);
    } catch (err) {
      toast.error(err);
      setLoading(false);
    }
  };

  const sortParticipants = (participantList) =>
    participantList.sort((a, b) =>
      moment(a.createdAt).diff(moment(b.createdAt), 'seconds'),
    );
  const transformParticipantsToArray = (participantMap, clubCompanies) => {
    let allParticipants = [];
    let compParticipants = [];
    const _clubCompanies = [...clubCompanies];
    Object.keys(participantMap).forEach((key) => {
      switch (key) {
        case 'GUEST':
          allParticipants = [
            ...(participantMap[key] || []).map((part) => ({
              ...part,
              type: 'GUEST',
            })),
            ...allParticipants,
          ];
          break;
        case 'USER':
          allParticipants = [
            ...(participantMap[key] || []).map((part) => ({
              ...part,
              type: 'USER',
            })),
            ...allParticipants,
          ];
          break;
        default: {
          const foundCompanyIdx = clubCompanies.findIndex(
            (item) => item.id === key,
          );
          if (foundCompanyIdx === -1) {
            break;
          }
          _clubCompanies[foundCompanyIdx].maxPartcipants =
            participantMap[key].maxAttendees;
          const foundCompany = _clubCompanies[foundCompanyIdx];
          compParticipants = [
            ...compParticipants,
            ...participantMap[key].participants.map((employee) => ({
              ...employee,
              company: foundCompany,
              type: 'COMPANY',
            })),
          ];
          break;
        }
      }
    });
    return {
      comp: _clubCompanies,
      particip: sortParticipants([...compParticipants, ...allParticipants]),
    };
  };

  const getQuestions = async (eventParticipants) => {
    setLoading(true);
    let allQuestions = [];
    try {
      const answers = await Api.questions.fetchAnswers(eventID);
      if (answers?.data.length <= 0 || !answers) {
        const domainQuestions =
          await Api.questions.fetchDomainSpecificQuestions(eventID);
        allQuestions = domainQuestions?.data?.question;
        if (domainQuestions.data === '') {
          setQuestions([]);
        } else {
          setQuestions(domainQuestions?.data?.question);
        }
        setUserAnswers([]);
      } else {
        const uniqueAllQuestions = answers.data.flatMap((q) =>
          q.answer.map((a) => a),
        );
        const hashmap = {};
        const uniqueArr = [];

        uniqueAllQuestions.forEach((obj) => {
          if (!hashmap[obj.id]) {
            hashmap[obj.id] = true;
            uniqueArr.push(obj);
          }
        });
        allQuestions = uniqueArr;
        setQuestions(uniqueArr);
        setUserAnswers(answers.data);
      }
      const result = eventParticipants
        .filter(participant => participant.userId !== '' && participant.userId !== null)
        .map((participant) => {
          const answerObj =
            answers?.data?.find((a) => a.userId === participant.userId)?.answer ||
            [];
          let answersMap = {};

          if (Array.isArray(answerObj)) {
            const transformedAnswers = {};

            answerObj.forEach((ans) => {
              const { question } = ans;
              const { answer } = ans;

              let uniqueQuestion = question;
              let counter = 1;
              while (transformedAnswers[uniqueQuestion] !== undefined) {
                uniqueQuestion = `${question}__..__${counter}`;
                counter += 1;
              }

              transformedAnswers[uniqueQuestion] = answer;
            });

            answersMap = transformedAnswers;
          } else {
            answersMap = answerObj;
          }

          const defaultAnswers = allQuestions.reduce(
            (acc, cur) => ({ ...acc, [cur.question]: '-- --' }),
            {},
          );

          const finalAnswers = { ...defaultAnswers, ...answersMap };

          return { [participant.userId]: finalAnswers };
        });
      setQuestionsNansewrs(result);
      setLoading(false);
    } catch (err) {
      // toast.error(err);
      setLoading(false);
    }
  };

  const getPaticipants = async () => {
    setLoading(true);
    try {
      const { data: participantsMap } = await Api.events.getParticipants(
        eventID,
      );
      const { data: deleted } = await Api.events.getDeletedParticipants(
        eventID,
      );
      const clubCompanies = await Api.companies.getCompanies(
        10000,
        1,
        '',
        'name',
        false,
        '',
      ).then(({ data }) => data.data);
      const { comp, particip } = transformParticipantsToArray(
        participantsMap,
        clubCompanies,
      );
      const mappedParticipants = particip.map((item) => ({
        ...item,
        showSave: false,
        toggled: false,
      }));
      const deletedUserWithCompany = deleted.map((dp) => {
        const companyEdit = { ...dp };
        if (companyEdit.companyId) {
          const companyName = clubCompanies.find(c => c.id === companyEdit.companyId);

          if (companyName) {
            companyEdit.company = companyName.name;
          }
        }
        return companyEdit;
      });
      setCompanies(comp);
      setParticipants(mappedParticipants);
      setDeletedParticipants(deletedUserWithCompany);
      getQuestions(mappedParticipants);
      setLoading(false);
    } catch (err) {
      toast.error(err);
      setLoading(false);
    }
  };
  const getParticipantType = (values) => {
    if (values?.selectedUser) {
      return 'USER';
    }
    if (values?.selectedCompany) {
      return 'COMPANY';
    }
    return 'GUEST';
  };

  const handleInviteParticipant = async (values) => {
    const participantType = getParticipantType(values);
    const nonCompayUserPhone =
      typeof values?.userPhone === 'string'
        ? values?.userPhone
        : values?.userPhone?.phone;

    let payload = {};
    switch (participantType) {
      case 'COMPANY':
        payload = {
          userId: values.selectedEmployee.value.userId,
          companyId: values?.selectedCompany?.value?.id || null,
          firstname: values.selectedEmployee.value.firstName,
          lastname: values.selectedEmployee.value.lastName,
          phoneNumber: values.selectedEmployee.value.phone,
          participantType: 'USER',
          friendAmount: values.guestAmount ? Number(values.guestAmount) : 0,
          additionalNotes: '',
        };
        break;
      case 'USER':
        payload = {
          userId: values?.selectedUser?.userId,
          companyId: values?.selectedCompany?.value?.id || null,
          firstname: values.firstName,
          lastname: values.lastName,
          phoneNumber: nonCompayUserPhone,
          participantType: 'USER',
          friendAmount: values.guestAmount ? Number(values.guestAmount) : 0,
          additionalNotes: '',
        };
        break;
      case 'GUEST':
        payload = {
          userId: null,
          companyId: null,
          firstname: values.firstName,
          lastname: values.lastName,
          phoneNumber: values.phoneNumber,
          email: values.email,
          participantType: 'GUEST',
          friendAmount: values.guestAmount ? Number(values.guestAmount) : 0,
          additionalNotes: values?.comments || '',
        };
        break;
      default:
    }

    setLoading(true);
    try {
      if (currentParticipant) {
        await Api.events.updateParticipant(payload, currentParticipant.id);
      } else {
        await Api.events.addParticipants(payload, eventID);
      }
      getPaticipants();
      setInviteModelVisible(false);
      //  toast.success(t('EventParticipantsPage.participantCreated'));
    } catch (e) {
      setLoading(false);
      console.log(e);
      // toast.error(t(`${e.data?.message}`) || `${'somethingWentWrong'}`);
    }
  };

  const exportParticpants = async () => {
    setLoading(true);

    const separator = ';';

    try {
      const questionsWithUserId = questionsNanswers.map((question) => {
        const userId = Object.keys(question)[0];
        return { ...question[userId], userId };
      });

      const totalCount = (param) =>
        participants.reduce((tot, curr) => {
          return tot + curr[param];
        }, 0);
      const participantsAndAnswers = participants.map((participant) => {
        const participantAnswer = questionsWithUserId.find(
          (question) => question.userId === participant.userId,
        );
        return { ...participant, answers: participantAnswer };
      });

      const totalParticipantRow = `${t(
        'EventParticipantsPage.numberOfParticipants',
      )} = ${totalCount('friendAmount') + participants.length}`;

      const questionFieldsSet = Array.from(
        new Set(
          questionsWithUserId.flatMap((question) => [...Object.keys(question)]),
        ),
      ).filter((question) => question !== 'userId');

      const totalFriendCount = participants.reduce((tot, curr) => {
        return tot + curr.friendAmount;
      }, 0);
      const addTotalAsFinalRow = `${separator}${separator}${separator}${separator}${separator}${separator}${separator}${totalFriendCount + participants.length
        }${separator}${totalCount('attendedAmount')}`;
      const finalRow = addTotalAsFinalRow.replaceAll(',', separator);

      const fields = `${t('shared.firstname')}${separator} ${t(
        'shared.lastname',
      )}${separator} ${t('shared.phone')}${separator} ${t('shared.email')}${separator} ${p(
        'participantType',
      )}${separator} ${t(
        'EventParticipantsPage.numberOfGuests',
      )}${separator} ${t('shared.comments')}${separator} ${t(
        'shared.attendees',
      )}${separator} ${t('shared.attended')}${separator} ${t(
        'shared.company',
      )}${separator} ${t('shared.questions')}${separator}${questionFieldsSet
        .map((questionField) => {
          return ` ${questionField.split('__..__')[0]}`;
        })
        .join(separator)}${separator} ${separator} ${totalParticipantRow}`;
      const values = participantsAndAnswers
        .map((participant) => {
          const answers = participant.type === 'GUEST' ? ['-- --'] : questionFieldsSet.map((questionField) => {
            if (
              participant.answers[questionField] &&
              Array.isArray(participant.answers[questionField]) &&
              participant.answers[questionField].length > 0
            ) {
              return `"${participant.answers[questionField].join(',')}"`;
            }
            return `"${participant.answers[questionField] || '-- --'}"`;
          });
          return [
            `"${participant.firstname}"` || '',
            `"${participant.lastname}"` || '',
            `"${participant.phoneNumber}"` || '',
            `"${participant.email}"` || '',
            `"${participant.participantType}"` || '',
            `"${participant.friendAmount}"` || '0',
            `"${participant.additionalNotes}"` || '',
            `"${participant.friendAmount + 1}"` || '0',
            `"${participant.attendedAmount}"` || '0',
            participant.company?.name
              ? `"${participant.company?.name}"`
              : '',
            '', // Empty column for quesitons
            answers.join(separator),
          ].join(separator);
        })
        .join('\n');

      const csv = `${fields}\n${values}\n${finalRow}`;

      fileDownload(csv, `${event.title} Event Participants.csv`);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error(err || `${p('listExportingERROR')}'!'`);
    }
  };

  const exportParticpantsXLSX = async () => {
    setLoading(true);

    try {
      const questionsWithUserId = questionsNanswers.map((question) => {
        const userId = Object.keys(question)[0];
        return { ...question[userId], userId };
      });

      const participantsAndAnswers = participants.map((participant) => {
        const participantAnswer = questionsWithUserId.find(
          (question) => question.userId === participant.userId,
        );
        return { ...participant, answers: participantAnswer };
      });

      const data = participantsAndAnswers.map(i => {
        const result = {};
        result[`${t('shared.firstname')}`] = i?.firstname;
        result[`${t('shared.lastname')}`] = i?.lastname;
        result[`${t('shared.phone')}`] = i?.phoneNumber;
        result[`${t('shared.email')}`] = i?.email;
        result[`${p('participantType')}`] = i?.participantType;
        result[`${t('EventParticipantsPage.numberOfGuests')}`] = i?.friendAmount + 1;
        result[`${t('shared.comments')}`] = i?.additionalNotes;
        result[`${t('shared.attended')}`] = i?.attendedAmount;
        result[`${t('shared.company')}`] = i?.company?.name;
        return {
          ...result,
          ...i?.answers,
        };
      });

      exportToExcelFile(data, `${event.title} Event Participants`);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error(err || `${p('listExportingERROR')}'!'`);
    }
  };

  const getScheduleMessagesByServiceId = async (page = 1, pageSize = 10) => {
    setLoading(true);
    try {
      let messageByEventId = [];

      if (messageStatus === t('ScheduledMessagesPage.filterByStatus')) {
        messageByEventId =
          await Api.scheduleMessages.getScheduleMessagesByServiceId(
            eventID,
            page,
            pageSize,
          );
      } else {
        messageByEventId =
          await Api.scheduleMessages.getScheduleMessagesByServiceIdByStatus(
            eventID,
            page,
            pageSize,
            messageStatus,
          );
      }

      if (messageStatus === 'Scheduled') {
        setNumberOfPendingMessagesPerEvent(messageByEventId?.data?.total);
      }

      setScheduleMessagesForEvent(messageByEventId.data.data);
      setTotalAmountOfScheduledMessages(messageByEventId.data.total);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error(err || `${t('ScheduledMessagesPage.GetByOfferIdError')}`);
    }
  };

  const sendMessage = async (values) => {
    const payload = {
      messageTitle: values.title,
      messageBody: values.content,
      type: values.type,
      receiverType: values.receiverType,
      scheduledDate:
        values.timeToSend === 'SCHEDULED'
          ? moment.utc(startDate).format()
          : null,
      clubName: config.cloudIdName,
    };

    let resCount = '';
    try {
      if (values.timeToSend === 'SCHEDULED' && scheduleMessageID) {
        resCount = await Api.events.updateMessage(
          eventID,
          payload,
          scheduleMessageID,
        );

        setClearContactPage(true);
        setScheduleMessageID(null);
      } else if (values.timeToSend === 'INSTANT') {
        resCount = await Api.events.sendMessage(eventID, payload);
        setClearContactPage(true);
        setScheduleMessageID(null);
      } else {
        resCount = await Api.events.scheduleMessage(eventID, payload);
        setClearContactPage(true);
        setScheduleMessageID(null);
      }
      if (values.receiverType === 'PRIVILAGED') {
        toast.success(
          `${p('messageWillBeSentTo')} ${resCount.data === 1
            ? `${resCount.data} ${t('shared.person')}`
            : `${t('EventsContactForm.invited')}`
          }`,
        );
      } else if (values.receiverType === 'ALL') {
        toast.success(
          `${p('messageWillBeSentTo')}  ${t('shared.participants')}/${t(
            'EventsContactForm.invited',
          )}`,
        );
      } else {
        toast.success(
          `${p('messageWillBeSentTo')} ${resCount.data === 1
            ? `${resCount.data} ${t('shared.person')}`
            : `${t('shared.participants')}`
          }`,
        );
      }
    } catch (err) {
      toast.error(err);
    }
    getScheduleMessagesByServiceId();
  };

  useEffect(() => {
    getData();
    setLoading(true);
    if (eventID) {
      getEvent();
      getPaticipants();
      getScheduleMessagesByServiceId();
      setLoading(false);
    }
  }, [eventID, messageStatus]);
  const [selectedCountry, setSelectedCountry] = useState(null);

  const filterGroupsLevels = (selectedArray, targetAudience) => {
    if (EventTypes.TargetAudience.private === targetAudience) return null;
    if (selectedArray.length === 0) {
      return null;
    }
    const valueArray =
      Array.isArray(selectedArray) && selectedArray.map(({ value }) => value);
    const containsForAll =
      Array.isArray(selectedArray) &&
      valueArray.some((item) => Array.isArray(item));
    const containsNotApplicable =
      Array.isArray(selectedArray) && valueArray.some((item) => !item);
    if (containsForAll) {
      return [];
    }
    if (containsNotApplicable) {
      return null;
    }
    if (Array.isArray(selectedArray)) {
      return selectedArray.map((item) => item.label);
    }
    return null;
  };

  const filterTiers = (selectedArray, targetAudience) => {
    if (EventTypes.TargetAudience.partner === targetAudience) return null;
    if (selectedArray.length === 0) {
      return null;
    }
    const valueArray =
      Array.isArray(selectedArray) && selectedArray.map(({ value }) => value);
    const containsForAll =
      Array.isArray(selectedArray) &&
      valueArray.some((item) => Array.isArray(item));
    const containsNotApplicable =
      Array.isArray(selectedArray) && valueArray.some((item) => !item);
    if (containsForAll) {
      return [];
    }
    if (containsNotApplicable) {
      return null;
    }
    if (Array.isArray(selectedArray)) {
      return selectedArray.map((item) => item.value);
    }
    return null;
  };

  const onSubmit = async (values) => {
    const accessLevels = filterGroupsLevels(
      values?.eventLevels,
      values.targetAudience,
    );
    const accessCategories = filterGroupsLevels(
      values?.eventGroups,
      values.targetAudience,
    );
    const accessTiers = filterTiers(values?.eventTiers, values.targetAudience);
    if (
      values.targetAudience === EventTypes.TargetAudience.partner ||
      values.targetAudience === EventTypes.TargetAudience.both
    ) {
      if (
        (!accessLevels && !accessCategories) ||
        (accessLevels === null && !accessCategories === null)
      ) {
        setErrorMessage(f('levelOrGroupRequiredMessage'));
        setShowPermissionModal(true);
        return;
      }
    }
    if (values.targetAudience === EventTypes.TargetAudience.private) {
      if (!accessTiers || accessTiers === null) {
        setErrorMessage(f('levelOrGroupRequiredMessage'));
        setShowPermissionModal(true);
        return;
      }
    }
    const payload = {
      title: values.title,
      mapsUrl: values.mapsUrl,
      address: values.address,
      zip: values.zip,
      description: values.description,
      ...(!values.eventCategories?.value && {
        maxAttendees: Number(values.maxAttendees),
        maxAccountAttendees:
          Number(values.maxAccountAttendees) === 0 || null
            ? Number(values.maxAttendees)
            : Number(values.maxAccountAttendees),
      }),
      startDate: new Date(values.startDate).toISOString(),
      endDate: new Date(values.endDate).toISOString(),
      registrationStart: values.registrationStart
        ? new Date(values.registrationStart).toISOString()
        : null,
      registrationEnd: values.registrationEnd
        ? new Date(values.registrationEnd).toISOString()
        : null,
      categoryId: values.eventCategories?.value,
      accessLevels,
      accessCategories,
      accessTiers,
      targetAudience: values.targetAudience,
      feedVisibility: values.feedVisibility === 'ALL',
      shouldNotify: values.shouldNotify === 'YES',
      notify_user: values.notify_user,
      urlDescription: values.urlDescription,
      urlText: values.urlText,
      waitlistEnabled: values.waitlistEnabled,
      ...(isValidUrl(values.websiteUrl) &&
        values.websiteUrl !== '' && {
        websiteUrl: values.websiteUrl,
      }),
      ...(selectedCountry !== null && {
        country: selectedCountry,
      }),
      ...(values.city !== '' && {
        city: values.city,
      }),
    };
    setLoading(true);
    try {
      const image = values.imageUrl;
      // if (accessLevels === null && accessCategories === null) {
      //   toast.error(t('EventCategories.groupOrLevelMustBeSelected'));
      //   throw new Error(t('EventCategories.groupOrLevelMustBeSelected'));
      // }
      if (eventID) {
        await Api.events.update(payload, eventID);
        if (image) await image.promise?.('event', 'imageUrl', eventID);
        toast.success(p('eventUpdated'));
        setLoading(false);
      } else {
        const { data: resEvent } = await Api.events.create(payload);
        if (image) await image.promise?.('event', 'imageUrl', resEvent.id);
        toast.success(p('eventCreated'));
        history.replace('/events');
      }
    } catch (err) {
      setLoading(false);
      toast.error(err);
    }
  };

  const checkCount = async (values) => {
    const payload = {
      type: values.type,
      to: values.to,
      send_count: true,
    };
    setLoading(true);
    try {
      const resCount = await Api.events.sendMessage(eventID, payload);
      toast.success(
        `${p('messageWillBeSentTo')} ${resCount.data === 1
          ? `${resCount.data} ${t('shared.person')}`
          : `${resCount.data} ${t('shared.persons')}`
        }`,
      );
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error(err);
    }
  };

  const changeGuestAmount = async (newGuests, participant) => {
    try {
      setLoading(true);
      const payload = {
        ...participant,
        friendAmount: newGuests,
      };
      await Api.events.updateParticipant(payload, participant.id);
      toast.success(p('guestAmountUpdated'));
      const participantsCopy = [...participants];
      const foundItemIndex = participantsCopy.findIndex(
        (item) => item.id === participant.id,
      );
      participantsCopy[foundItemIndex].friendAmount = newGuests;
      setParticipants(participantsCopy);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      toast.error(p('addGuestError'));
    }
  };

  const markParticipantAttendance = async (participantId, attendedAmount) => {
    try {
      setLoading(true);
      const attended = attendedAmount > 0;
      await Api.events.markAttendance(attendedAmount, attended, participantId);
      toast.success(t('EventParticipantsPage.markedAttendance'));
      const participantsCopy = [...participants];
      const foundItemIndex = participantsCopy.findIndex(
        (item) => item.id === participantId,
      );
      participantsCopy[foundItemIndex].status = attended
        ? 'ATTENDED'
        : 'ACCEPTED';
      participantsCopy[foundItemIndex].attendedAmount = attendedAmount;
      setParticipants(participantsCopy);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      toast.error(t(`EventsForm.${e.data.message}`));
    }
  };
  const markMultipleAttendance = async (attendantIds) => {
    try {
      setLoading(true);
      await Promise.all(
        attendantIds.map((id) => Api.events.markAttendance(true, id)),
      );
      toast.success(p('markedAttendance'));
      const participantsCopy = participants.map((item) => ({
        ...item,
        status: attendantIds.includes(item.id) ? 'ATTENDED' : item.status,
      }));
      setParticipants(participantsCopy);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      toast.error(t('EventParticipantsPage.eventNotFinished'));
    }
  };

  const saveAnswers = async (payload) => {
    setLoading(true);
    await Api.questions.saveAnswers(event.id, payload);
    await getPaticipants();
    setLoading(false);
  };

  return (
    <Container>
      <AlertModal
        visible={showPermissionModal}
        modalType={AlertModalType.ERROR}
        handleModal={() => setShowPermissionModal(false)}
        message={errorMessage}
      />
      <Loading loading={loading} />
      <Row>
        <Col>
          <Card>
            <CardBody style={{ padding: 0 }}>
              <NavTab
                tabs={[
                  t('shared.event'),
                  {
                    tab: (
                      <>
                        {t('shared.communication')}
                        {numberOfPendingMessagesPerEvent > 0 && <Badge style={{ marginLeft: 5 }} color="primary">{numberOfPendingMessagesPerEvent}</Badge>}
                      </>
                    ),
                    disabled: !eventID,
                  },
                  { tab: t('shared.participants'), disabled: !eventID },
                  { tab: t('question.questions'), disabled: !eventID },
                  { tab: t('EventsPage.waitlist'), disabled: !eventID },
                ]}
                activeTab={tab}
                setTab={setTab}
                size="sm"
                style={{ marginBottom: 0 }}

              />
              <TabContent activeTab={tab}>
                <TabPane style={{ padding: 10 }} tabId="1">
                  <EventsForm
                    eventForm={eventForm}
                    event={event}
                    onSubmit={onSubmit}
                    eventCategories={eventCategories}
                    eventLevels={eventLevels}
                    eventGroups={eventGroups}
                    eventTiers={eventTiers}
                    setSelectedCountry={setSelectedCountry}
                    numberOfPendingMessagesPerEvent={
                      numberOfPendingMessagesPerEvent
                    }
                  />
                </TabPane>
                <TabPane style={{ padding: 10 }} tabId="2">
                  <EventsContactForm
                    onSubmit={sendMessage}
                    checkCount={checkCount}
                    eventID={eventID}
                    scheduleMessageID={scheduleMessageID}
                    setScheduleMessageID={setScheduleMessageID}
                    setScheduleMessagesForEvent={setScheduleMessagesForEvent}
                    scheduleMessagesForEvent={scheduleMessagesForEvent}
                    getScheduleMessagesByServiceId={
                        getScheduleMessagesByServiceId
                      }
                    totalAmountOfScheduledMessages={
                        totalAmountOfScheduledMessages
                      }
                    clearContactPage={clearContactPage}
                    setClearContactPage={setClearContactPage}
                    messageStatus={messageStatus}
                    setMessageStatus={setMessageStatus}
                    startDate={startDate}
                    setStartDate={setStartDate}
                    setLoading={setLoading}
                  />
                </TabPane>
                <TabPane style={{ padding: 10 }} tabId="3">
                  <EventsPariticipantForm
                    event={event}
                    companies={companies}
                    setCompanies={setCompanies}
                    handleInviteParticipant={handleInviteParticipant}
                    inviteModelVisible={inviteModelVisible}
                    setInviteModelVisible={setInviteModelVisible}
                    changeGuestAmount={changeGuestAmount}
                    participants={participants}
                    setParticipants={setParticipants}
                    deletedParticipants={deletedParticipants}
                    setDeletedParticipants={setDeletedParticipants}
                    setLoading={setLoading}
                    getPaticipants={getPaticipants}
                    currentParticipant={currentParticipant}
                    setCurrentParticipant={setCurrentParticipant}
                    markParticipantAttendance={markParticipantAttendance}
                    markMultipleAttendance={markMultipleAttendance}
                    exportParticpants={exportParticpants}
                    exportParticpantsXLSX={exportParticpantsXLSX}
                    questionsNanswers={questionsNanswers}
                    questions={questions}
                    userAnswers={userAnswers}
                    saveAnswers={saveAnswers}
                    maxGuestAmount={maxGuestAmount}
                    setMaxGuestAmount={setMaxGuestAmount}
                  />
                </TabPane>

                <TabPane style={{ padding: 10 }} tabId="4">
                  <EventState>
                    <QuestionCard eventId={eventID} />
                  </EventState>
                </TabPane>
                <LazyTabPane activeTab={tab} tabId="5">
                  <WaitlistPage eventId={eventID} />
                </LazyTabPane>
              </TabContent>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default withRouter(
  connect((state) => ({
    eventForm: getFormValues('events_form')(state),
  }))(EventsFormPage),
);
