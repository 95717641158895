import React, { useEffect } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Col, Button, Row } from 'reactstrap';
import { parsePhoneNumber } from 'react-phone-number-input';
import AddIcon from 'mdi-react/AddIcon';
import ContactValidate from './contactInfoValidate';
import renderField from '../../../shared/components/custom/Field';
import t, { partial } from '../../../util/translation/translation';
import renderCheckBoxField from '../../../shared/components/form/CheckBox';
import FormBox from '../../../shared/components/custom/FormBox';

const s = partial('shared');
const p = partial('AddEmployee');

const ContactInfoForm = ({ isEdit, handleSubmit, initialize, user }) => {
  let countryCode;
  let phoneNumber;
  try {
    const parsedPhone = parsePhoneNumber(user.phoneNumber);
    countryCode = parsedPhone.countryCallingCode;
    phoneNumber = parsedPhone.nationalNumber;
  } catch (err) {
    countryCode = undefined;
    phoneNumber = undefined;
  }
  useEffect(() => {
    if (user?.userCloudId) {
      initialize({
        firstName: user.firstname,
        lastName: user.lastname,
        phone: user.phoneNumber,
        countryCode,
        phoneNumber,
        email: user.email,
        userId: user.userCloudId,
        companyPosition: user.companyPosition,
        visibleCompany: user.visibleCompany,
        visiblePrivate: user.visiblePrivate,
        isPrimaryContact: user.isPrimaryContact,
        isVIP: user.isVIP,
      });
    } else {
      initialize({
        firstName: null,
        lastName: null,
        phoneNumber: null,
        countryCode: null,
        email: null,
        userId: null,
        companyPosition: null,
        visibleCompany: false,
        visiblePrivate: false,
        isPrimaryContact: false,
        isVIP: false,
      });
    }
  }, [user]);
  return (
    <form className="form" onSubmit={handleSubmit}>
      {/* <Row> */}
      {/* <Col md={12}>
          <div className="mb-2">
            <strong>{p('addAbusinessCardManually')}</strong>
          </div>
        </Col> */}
      <Col lg={12} md={12} className="mb-3">
        <FormBox title={p('addAbusinessCardManually')} >
          <Col lg={12} md={12} >
            <Row>
              <Col lg={6} md={12}>
                <div className="form__form-group">
                  <span className="form-header">{p('firstName')}</span>
                  <Field name="firstName" component={renderField} placeholder={p('firstName')} />
                </div>
              </Col>
              <Col lg={6} md={12}>
                <div className="form__form-group">
                  <span className="form-header">{p('lastName')}</span>
                  <Field name="lastName" component={renderField} placeholder={p('lastName')} />
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={1} md={12}>
                <div className="form__form-group">
                  <span className="form-header">{s('countryCode')}</span>
                  <Field
                    name="countryCode"
                    component={renderField}
                    type="number"
                    placeholder={s('countryCodePlaceHolder')}
                  />
                </div>
              </Col>
              <Col lg={3} md={12}>
                <div className="form__form-group">
                  <span className="form-header">{p('phoneNumber')}</span>
                  <Field name="phoneNumber" component={renderField} type="text" placeholder="12345678" />
                </div>
              </Col>
              <Col lg={4} md={12}>
                <div className="form__form-group">
                  <span className="form-header">{s('email')}*</span>
                  <Field name="email" component={renderField} placeholder={p('email')} />
                </div>
              </Col>
              <Col md={4}>
                <div className="form__form-group">
                  <span className="form-header">{p('companyPosition')}</span>
                  <Field name="companyPosition" component={renderField} placeholder={p('companyPosition')} />
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={4} md={12}>
                <div className="form__form-group">
                  <span className="form-header">{t('Reports.visibilityInApp')}</span>
                  <Field
                    name="visibleCompany"
                    component={renderCheckBoxField}
                    label={t('Reports.visibleForPartners')}
                    className="colored-click"
                  />
                  <Field
                    name="visiblePrivate"
                    component={renderCheckBoxField}
                    label={t('Reports.visibleForPublic')}
                    className="colored-click"
                  />
                </div>
              </Col>
              <Col lg={2} md={12}>
                <div className="form__form-group">
                  <span className="form-header">Status</span>
                  <Field
                    name="isPrimaryContact"
                    component={renderCheckBoxField}
                    label={p('primaryContact')}
                    className="colored-click"
                  />
                  <Field
                    name="isVIP"
                    component={renderCheckBoxField}
                    label="VIP"
                    className="colored-click"
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </FormBox>
      </Col>
      <Col>
        {isEdit ? (
          <Button type="submit" color="primary" outline size="sm">
            {s('update')}
          </Button>
          ) : (
            <Button size="sm" className="green-button no-style">
              <p>
                <AddIcon />
                {p('addContact')}
              </p>
            </Button>
          )}
        <Link to="/companies">
          <Button type="button" color="secondary" size="sm">
            {s('close')}
          </Button>
        </Link>
      </Col>
      {/* </Row> */}
    </form>
  );
};

ContactInfoForm.propTypes = {
  user: PropTypes.shape({
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    id: PropTypes.string,
    phone: PropTypes.string,
    countryCode: PropTypes.string,
    email: PropTypes.string,
  }),
  handleSubmit: PropTypes.func.isRequired,
};

ContactInfoForm.defaultProps = {
  user: undefined,
};

const reduxF = reduxForm({
  form: 'contactInfoForm',
  validate: ContactValidate,
});

export default reduxF(ContactInfoForm);
