/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react';
import { Button, Badge, Col, Row, Card, NavLink, PopoverBody, Table, UncontrolledTooltip, ButtonGroup, UncontrolledPopover } from 'reactstrap';
import { toast } from 'react-toastify';
import EditIcon from 'mdi-react/EditIcon';
import DeleteIcon from 'mdi-react/DeleteIcon';
import EyeIcon from 'mdi-react/EyeIcon';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import t from '../../../util/translation/translation';
import Pagination from '../pagination/Pagination';
import Loading from '../custom/Loading';
import Api from '../../../util/api';
import { formatDateTime } from '../../../util/date-and-time';
import { getMessageType, getOriginLink, getTitleAndBody } from './utils';
import useModal from '../../hooks/useModal';
import DeleteModal from '../custom/DeleteModal';

const SentMessagesTable = () => {
  const history = useHistory();
  const [order, setOrder] = useState({
    page: 1,
    size: 10,
  });
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [handleModal, visible, deleteID] = useModal();
  const getScheduledMessages = async (page = 1, pageSize = 10) => {
    setLoading(true);
    try {
      const { data: scheduledMessagesRes } = await Api.scheduleMessages.getScheduleMessages(page, pageSize, 'Scheduled');
      const filledData = await Promise.all(
        scheduledMessagesRes.data.map((message) => getOriginLink(message).then((originalLinkResult) => (
          {
            ...message,
            originalLinkResult,
          }
        )).catch(() => (
          {
            ...message,
            originalLinkResult: { title: null, link: null },
          }
        ))));
      setMessages(filledData);
      setLoading(false);
      setTotal(scheduledMessagesRes.total);
    } catch (err) {
      toast.error(err || 'error');
      setLoading(false);
    }
  };
  const deleteScheduledMessage = async () => {
    setLoading(true);
    try {
      await Api.scheduleMessages.deleteMessage(deleteID);
      toast.success(t('shared.deleteSuccessful'));
    } catch (err) {
      toast.error(err);
    } finally {
      handleModal();
      setOrder({ page: 1, size: 10 });
      getScheduledMessages();
    }
  };

  const onChangePageSystem = (pager) => {
    if (order.page !== pager.currentPage || order.size !== pager.pageSize) {
      getScheduledMessages(pager.currentPage, pager.pageSize);
      setOrder({
        page: pager.currentPage,
        size: pager.pageSize,
      });
    }
  };
  useEffect(() => {
    getScheduledMessages();
  }, []);



  const renderSentMessages = () => {
    return messages.sort((a, b) => (new Date(a.date).getTime() - new Date(b.date).getTime())).map((message, i) => {
      let typeColor;
      switch (getMessageType(message)) {
        case 'push': typeColor = 'success'; break;
        case 'sms': typeColor = 'danger'; break;
        case 'email': typeColor = 'warning'; break;
        default: typeColor = 'default';
      }
      const { title, link } = message.originalLinkResult;
      const { messageTitle, messageBody } = getTitleAndBody(message);
      return (
        <tr style={{ background: (i) % 2 ? '#fff' : '#F9FAFB' }} key={`renderScheduledMessages-${message.id}`}>
          <td>
            {messageTitle}
          </td>
          {/* eslint-disable-next-line no-nested-ternary */}
          <td>{message?.date ? formatDateTime(message?.date) : (message?.createdAt ? formatDateTime(message?.createdAt) : '-- --')}</td>
          <td><Badge color={typeColor}>{getMessageType(message)}</Badge></td>
          <td>
            <NavLink href={link} disabled={!link}>
              {title}
            </NavLink>
          </td>
          <td>
            <UncontrolledTooltip placement="bottom" target={`editBtn-${message.id}`}>{t('shared.edit')}</UncontrolledTooltip>
            <UncontrolledTooltip placement="bottom" target={`deleteBtn-${message.id}`}>{t('shared.delete')}</UncontrolledTooltip>
            <ButtonGroup size="sm">
              <Button size="sm" className="margin-0 py-1 px-2" id={`messageBody${message.id}`} outline><EyeIcon className="m-0" /></Button>
              <UncontrolledPopover trigger="legacy" placement="left" target={`messageBody${message.id}`}>
                <PopoverBody>
                  <div className="margin-5">{t('shared.title')}: {messageTitle}<hr className="my-1"/>{t('shared.description')}: {messageBody}</div>
                </PopoverBody>
              </UncontrolledPopover>
              <Button onClick={() => { history.push({ pathname: `/messages/edit/${message.id}`, state: message }); }} id={`editBtn-${message.id}`} outline>
                <EditIcon />
              </Button>
              <Button onClick={() => { handleModal(message.id); }} id={`deleteBtn-${message.id}`} outline>
                <DeleteIcon />
              </Button>
            </ButtonGroup>
          </td>
        </tr>
      );
    });
  };
  return (
    <Card>
      <DeleteModal
        visible={visible}
        type={t('ScheduledMessagesPage.scheduledMessages')}
        handleModal={handleModal}
        modalAction={deleteScheduledMessage}
      />
      <Loading loading={loading} />
      <Col>
        <Row>
          <h3 className="page-title mb-0">{t('ScheduledMessagesPage.scheduledMessages')}</h3>
        </Row>
      </Col>
      <div className="table-box">
        <Table responsive striped>
          <thead>
            <tr>
              <th>{t('shared.title')}</th>
              <th>{t('shared.publication')}</th>
              <th>{t('shared.type')}</th>
              <th>{t('shared.origin')}</th>
              <th/>
            </tr>
          </thead>
          <tbody>
            {renderSentMessages()}
          </tbody>
        </Table>
      </div>
      <Pagination
        items={total}
        onChangePage={onChangePageSystem}
        initialPage={0}
      />
    </Card>
  );
};



export default SentMessagesTable;
