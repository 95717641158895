/* eslint-disable no-unused-vars */
import React from 'react';
import { Col, Row, UncontrolledTooltip } from 'reactstrap';
import DragHandle from './DragHandle';
import DeleteIcon from './icons/DeleteIcon';
import UpArrowIcon from './icons/UpArrowIcon';
import DownArrowIcon from './icons/DownArrowIcon';
import QuestionType from './QuestionType';
import EditPencilIcon from './icons/EditPencilIcon';
import { partial } from '../../../../util/translation/translation';
import Utilities from './utilities';

export default function PickedQuestionItem(props) {
  const { question, onUpdate, index } = props;
  const shouldMoveUpDisabled = props.first;
  const shouldMoveDownDisabled = props.last;

  const qT = partial('question');

  const initialIsRequiredValue = Array.isArray(question.required) && question.required.includes('required');

  const onMandatoryChange = (evt) => {
    const requiredNextState = evt.target.checked;

    if (onUpdate) {
      const newQUestion = { ...question, required: ['required'] };
      if (!requiredNextState) {
        delete newQUestion.required;
      }
      onUpdate(newQUestion);
    }
  };

  return (
    <div
      key={question.id}
      className="picked-question-card"
      onMouseEnter={() => Utilities.previewQuestion(question, index, 'picked-question-card')}
      onMouseLeave={() => Utilities.clearPreview()}
    >
      <div className="content-area">
        <Row>
          <Col className="question-content">
            <div className="float-left draggable-handle"><DragHandle /></div>
            <div className="question-description">
              {Utilities.trim(question.question)}
            </div>
          </Col>
          <Col className="col-3 d-flex justify-content-end">
            <QuestionType type={question.question_type} />
          </Col>
        </Row>
        {Array.isArray(question.choices) && (
          <div className="">
            <div className="options-label">{qT('options')}</div>
            <div className="d-flex flex-row choices-list">
              {question.choices.map((opt) => (
                <div key={opt.choice} className="option">{opt.choice}</div>
              ))}
            </div>
          </div>
        )}
      </div>
      <hr />
      <div className="content-area">
        <Row>
          <Col>
            <div className="form-check">
              <label className="form-check-label" htmlFor={`flexCheckDefault_${question.id}`}>
                <input
                  className="form-check-input"
                  type="checkbox"
                  onChange={onMandatoryChange}
                  checked={initialIsRequiredValue}
                  id={`flexCheckDefault_${question.id}`}
                />
                {qT('mandatory')}
              </label>
            </div>
          </Col>
          <Col className="col-3">
            <div className="card-actions">
              <button
                className="action-button red-border"
                onClick={() => props.onDelete(question)}
              >
                <DeleteIcon />
              </button>
              <>
                <div id={`btn-${question.id}`}>
                  <button
                    className="action-button"
                    disabled={props.editability.find((e) => e === question.id)}
                    onClick={() => props.onEdit(question)}
                  >
                    <EditPencilIcon />
                  </button>
                </div>
                {props.editability.find((e) => e === question.id) && (
                  <UncontrolledTooltip placement="top" target={`btn-${question.id}`} style={{ zIndex: 100 }}>
                    {qT('pleaseSaveTheQuestionnaireToEditThisQuestion')}
                  </UncontrolledTooltip>)
                }
              </>
              <button
                className="action-button move-up"
                disabled={shouldMoveUpDisabled}
                onClick={() => props.onMoveUp(question)}
              >
                <UpArrowIcon />
              </button>
              <button
                className="action-button move-down"
                disabled={shouldMoveDownDisabled}
                onClick={() => props.onMoveDown(question)}
              >
                <DownArrowIcon />
              </button>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
