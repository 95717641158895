import React, { useState, useEffect } from 'react';
import {
  Col, Card, CardBody,
  Row, Button,
} from 'reactstrap';
import TableIcon from 'mdi-react/TableIcon';
import AccountGroupIcon from 'mdi-react/AccountGroupIcon';
import NewspaperIcon from 'mdi-react/NewspaperIcon';
import StadiumIcon from 'mdi-react/StadiumIcon';
import SitemapIcon from 'mdi-react/SitemapIcon';
import CalendarIcon from 'mdi-react/CalendarIcon';
import TicketIcon from 'mdi-react/TicketIcon';
import DragIcon from 'mdi-react/DragIcon';
import WalletMembershipIcon from 'mdi-react/WalletMembershipIcon';
import GavelIcon from 'mdi-react/GavelIcon';
import YoutubeIcon from 'mdi-react/YoutubeIcon';
import BellAlertIcon from 'mdi-react/BellAlertIcon';
import ImageAreaIcon from 'mdi-react/ImageAreaIcon';
import BasketIcon from 'mdi-react/BasketIcon';
import StarCircleIcon from 'mdi-react/StarCircleIcon';
import BrightnessPercentIcon from 'mdi-react/BrightnessPercentIcon';
import ImageOutlineIcon from 'mdi-react/ImageOutlineIcon';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { toast } from 'react-toastify';
import Loading from '../../shared/components/custom/Loading';
import EmptyStateInfo from '../../shared/components/custom/EmptyStateInfo';
import Api from '../../util/api';
import { partial } from '../../util/translation/translation';
import useConfig from '../../util/useConfig';
import CustomWebsiteComponent from './CustomWebsiteComponent';

const getIconComponent = (type) => {
  switch (true) {
    case type.split('-')[0] === 'spotlight': return ImageAreaIcon;
    case type === 'stats': case type === 'standings': case type === 'playerStats': return TableIcon;
    case type === 'news': return NewspaperIcon;
    case type === 'questionaires': return NewspaperIcon;
    case type === 'players': return AccountGroupIcon;
    case type === 'tierBanner': return WalletMembershipIcon;
    case type === 'matchBanner': case type === 'matchCenter': case type === 'matchcenter': return StadiumIcon;
    case type === 'shop': return BasketIcon;
    case type === 'sponsors': return SitemapIcon;
    case type === 'events': return CalendarIcon;
    case type === 'lottery': case type === 'seasonBanner': case type === 'tickets': return TicketIcon;
    case type === 'offers': return BrightnessPercentIcon;
    case type === 'auctions': return GavelIcon;
    case type === 'youTubeFeed': return YoutubeIcon;
    case type === 'custom': return NewspaperIcon;
    case type === 'seasonMatchNotificationsBanner': return BellAlertIcon;
    case type === 'termsBanner': return BellAlertIcon;
    case type === 'NTFGoalFeed': return BellAlertIcon;
    case type === 'specific-news': return NewspaperIcon;
    default: return ImageOutlineIcon;
  }
};

const getPremium = (type) => {
  switch (true) {
    case type === 'youTubeFeed': return true;
    case type === 'custom': return true;
    case type === 'NTFGoalFeed': return true;
    default: return false;
  }
};

const getFeatures = (features, name) => {
  if (features?.includes(name)) {
    return true;
  }
  return false;
};

const WebsiteLayoutPage = () => {
  const config = useConfig();

  const features = config?.featureFlags;
  const [availableEntities, setAvailableEntities] = useState({ news: [], companies: [] });
  const [sidebar, setSidebar] = useState({
    Col0: {
      name: 'Components',
      items: [],
    },
  });
  const [mainScreen, setMainScreen] = useState({
    Col0: {
      name: 'Components',
      items: [],
    },
  });
  const [loadingComponents, setLoadingComponents] = useState(false);
  const [showModal, setShowModal] = useState(null);

  const p = partial('appLayout');
  const s = partial('shared');



  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      sidebar: sidebar.Col1.items.map((item, idx) => ({
      id: item.id,
      value: item.value || item.id,
      label: item.label || null,
      order: idx + 1,
    })),
    mainScreen: mainScreen.Col1.items.map((item, idx) => ({
      id: item.id,
      value: item.value || item.id,
      label: item.label || null,
      order: idx + 1,
    })),
  };
    try {
      await Api.app_layout.website.create(config.id, payload);
      toast.success(s('Toast.successfullEdit'));
    } catch {
      toast.error(s('somethingWentWrong'));
    }
  };
  const fetchEntities = async () => {
    const [news, companies, spotlight] = await Promise.all([
      Api.news.getNews({ page: 1, take: 100 }, { orderBy: 'publicationDate', direction: 'DESC' }).then(({ data }) => data.map((item) => ({ label: item.title, value: item.id }))),
      Api.companies.getCompaniesForClub().then((data) => data.map((item) => ({ label: item.name, value: item.id }))),
      Api.spotlight.getGroups({ page: 1, take: 100 }, { order: 'createdAt', direction: 'DESC' }).then(({ data }) => data.map((item) => ({ label: item.title, value: item.id }))),
    ]);
    const constructed = {
      news,
      companies,
      spotlight,
    };
    setAvailableEntities(constructed);
    return constructed;
  };
  const mapDbEntities = (dbEntities, storedEntities) => {
    const mapped = [];
    for (let i = 0; i < dbEntities.length; i++) {
      const item = dbEntities[i];
      if (item.id.includes('specific-')) {
        const specificEntities = storedEntities[item.id.split('specific-')[1]];
        const foundName = specificEntities.find((e) => e.value === item.value)?.label;
        mapped.push({ id: item.id, name: p(item.id), value: item.value, label: foundName });
      } else {
        mapped.push({ id: item.id, name: p(item.id) });
      }
    }
    return mapped;
  };
  const getInitialData = async () => {
    setLoadingComponents(true);
    try {
      const [{ data: layoutOptions }, getLayout] = await Promise.all([Api.app_layout.website.getComponents(), Api.app_layout.website.all(config.id).then((res) => {
        if (Object.keys(res.data).length === 0) {
          return {
            sidebar: [],
            mainScreen: [],
          };
        }
        return {
          ...res.data,
          sidebar: res.data.sidebar || [],
          mainScreen: res.data.mainScreen || [],
        };
      })]);
      const results = await fetchEntities();
      const { sidebar: dbSidebar, mainScreen: dbMainScreen } = getLayout;
      const specificSidebarComponents = [{ id: 'specific-news', name: p('specific-news'), value: null }, { id: 'specific-companies', name: p('specific-companies'), value: null }];
      const specificMainScreenComponents = [
        { id: 'specific-spotlight', name: p('specific-spotlight'), value: null },
        { id: 'news', name: p('news') },
        { id: 'matchBanner', name: p('matchBanner'), value: null },
        { id: 'featuredNews', name: p('featuredNews'), value: null }];
      const sidebarOptions = [...layoutOptions.sidebar.map(e => ({ id: e, name: p(e) })), ...specificSidebarComponents];
      const mainScreenOptions = [...(layoutOptions?.mainScreen?.map(e => ({ id: e, name: p(e) })) || []), ...specificMainScreenComponents];
      const sidebarObject = {
        Col0: {
          name: 'Components',
          items: sidebarOptions.filter(e => e.id.includes('specific-') ? true : !dbSidebar.some(h => h.value === e.id)),
        },
        Col1: {
          name: 'in-app',
          items: mapDbEntities(dbSidebar, results),
        },
      };
      const mainScreenObject = {
        Col0: {
          name: 'Components',
          items: mainScreenOptions.filter(e => e.id.includes('specific-') ? true : !dbMainScreen.some(h => h.value === e.id)),
        },
        Col1: {
          name: 'in-app',
          items: mapDbEntities(dbMainScreen, results),
        },
      };
      setSidebar(sidebarObject);
      setMainScreen(mainScreenObject);
      setLoadingComponents(false);
    } catch (err) {
      console.log(err);
      toast.error(err);
      setLoadingComponents(false);
    }
  };
  useEffect(() => {
    getInitialData();
  }, []);

  const onDragEnd = async (result, columns, setColumns, itemType) => {
    if (!result.destination) return;
    const { source, destination } = result;

    if (destination.droppableId === 'Col0') {
      const sourceColumn = columns[source.droppableId];
      const item = sourceColumn.items[source.index];
      if (item.id.includes('specific-')) {
        const sourceItems = [...sourceColumn.items];
        sourceItems.splice(source.index, 1);
        const columnObject = {
          ...columns,
          [source.droppableId]: {
            ...sourceColumn,
            items: sourceItems,
          },
        };
        setColumns(columnObject);
        return;
      }
    }
    let columnObject = {};
    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];
      let removed = null;
      let shouldShowModal = null;
      if (source.droppableId === 'Col0') {
        if (sourceItems[source.index].id.includes('specific-')) {
          [removed, shouldShowModal] = [sourceItems[source.index], sourceItems[source.index].id.split('specific-')[1]];
        } else {
           [removed] = sourceItems.splice(source.index, 1);
        }
      } else {
        [removed] = sourceItems.splice(source.index, 1);
      }

      destItems.splice(destination.index, 0, removed);
      columnObject = {
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems,
        },
      };
      setColumns(columnObject);
      if (shouldShowModal) {
        setShowModal({
          item: removed,
          type: itemType,
        });
      }
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      columnObject = {
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems,
        },
      };
      setColumns(columnObject);
    }
  };
  const renderDragNDrop = (columns, setColumns, dropfieldName) => (
    <DragDropContext
      onDragEnd={result => onDragEnd(result, columns, setColumns, dropfieldName)}
    >

      {Object.entries(columns).map(([columnId, column]) => {
        return (
          <Col
            key={column.name}
            xs={6}
            sm={6}
            md={6}
            xl={6}
          >
            <strong>{column.name === 'in-app' ? s('Active') : s('Available')}</strong>
            <Droppable droppableId={columnId} key={columnId}>
              {(provided, snapshot) => {
                return (
                  <div
                    className="comCol"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={{
                      background: snapshot.isDraggingOver
                        ? '#d9edff'
                        : '#F9FAFB',
                      borderWidth: 1,
                      borderRadius: 3,
                      borderStyle: 'solid',
                      borderColor: snapshot.isDraggingOver ? '#0469DC' : '#D7DAE0',
                    }}
                  >
                    {column.items.length === 0 &&
                      <div className="my-5">
                        <EmptyStateInfo icon="layout" title={s('emptyStateTitle')} text={column.name.split(' ').pop() === 'in-app' ? p('emptyAppComponents') : ''}/>
                      </div>
                    }
                    {column.items.map((item, index) => {
                      const IconComponent = getIconComponent(item.id);
                      const last = column.items.length === index + 1 ? 0 : '1px dashed #D7DAE0';
                      const disabled = (getPremium(item?.id) ? !getFeatures(features, item?.id) : false);
                      return (
                        <Draggable
                          key={`${item.id}-${item.value}`}
                          draggableId={`${item.id}-${item.value}-${columnId}`}
                          index={index}
                          isDragDisabled={disabled}
                        >
                          {(provided2, snapshot2) => {
                            return (
                              <div
                                className=""
                                ref={provided2.innerRef}
                                {...provided2.draggableProps}
                                {...provided2.dragHandleProps}
                                style={{
                                  opacity: disabled ? 0.4 : 1,
                                  backgroundColor: snapshot2.isDragging
                                  ? '#F5FAFF'
                                  : 'white',
                                color: snapshot2.isDragging
                                ? '#0469DC !important'
                                : 'black',
                                padding: 10,
                                borderTop: snapshot2.isDragging
                                ? '1px solid #0469DC'
                                : 0,
                                borderLeft: snapshot2.isDragging
                                ? '1px solid #0469DC'
                                : 0,
                                borderRight: snapshot2.isDragging
                                ? '1px solid #0469DC'
                                : 0,
                                borderBottom: snapshot2.isDragging
                                ? '1px solid #0469DC'
                                : last,
                                  ...provided2.draggableProps.style,
                                }}
                              >
                                <div style={{ margin: '0 auto', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                  <DragIcon style={{ widht: 30, color: '#D7DAE0' }} className="mt-1"/>
                                  <IconComponent style={{ margin: '5px 5px 0', padding: 3, widht: 10, borderRadius: 6, fill: snapshot2.isDragging ? '#0469DC' : '#121B2B', backgroundColor: '#D7E9FE' }} size={30} />
                                  {/* getPremium(item.id) */ false && <StarCircleIcon style={{ position: 'relative', right: 11, top: -11, color: '#703FF3', width: 12 }}/>}
                                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <p style={{ marginTop: 5, fontSize: 12, fontWeight: 700, lineHeight: '12px', color: snapshot2.isDragging ? '#0469DC' : 'black', marginBottom: 2 }}>{item.name}</p>
                                    {item?.label && <p style={{ fontSize: 10, fontWeight: 500, lineHeight: '10px', color: snapshot2.isDragging ? '#0469DC' : '#666666', margin: 0 }}>{item.label}</p>}
                                  </div>
                                </div>
                              </div>
                            );
                          }}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </div>
                );
              }}
            </Droppable>
          </Col>
        );
      })}
    </DragDropContext>
  );
  const handleComponentSave = (payload) => {
    const { item: showModalItem, type } = showModal;
    let selectedLayout = '';
    let selectedMethod = '';
    switch (type) {
      case 'sidebar':
        selectedLayout = sidebar;
        selectedMethod = setSidebar;
        break;
      case 'mainScreen':
        selectedLayout = mainScreen;
        selectedMethod = setMainScreen;
        break;
      default:
        return;
    }

    const updatedItems = selectedLayout.Col1.items.map((item) => {
      if (`${item.id}-${item.value}` === `${showModalItem.id}-${showModalItem.value}`) {
        return payload;
      }
      return item;
    });
    selectedMethod({ ...selectedLayout, Col1: { ...selectedLayout.Col1, items: updatedItems } });
    setShowModal(null);
  };
  const handleModalClosed = () => {
    let selectedLayout = '';
    let selectedMethod = '';
    const { item: showModalItem, type } = showModal;
    switch (type) {
      case 'sidebar':
        selectedLayout = sidebar;
        selectedMethod = setSidebar;
        break;
      case 'mainScreen':
        selectedLayout = mainScreen;
        selectedMethod = setMainScreen;
        break;
      default:
        return;
    }
    const newLayout = selectedLayout.Col1.items.filter((item) => `${item.id}-${item.value}` !== `${showModalItem.id}-${showModalItem.value}`);
    selectedMethod({ ...selectedLayout, Col1: { ...selectedLayout.Col1, items: newLayout } });
    setShowModal(null);
  };
  return (
    <div>
      <Loading loading={loadingComponents} />
      <CustomWebsiteComponent availableEntities={availableEntities} showModal={showModal} onClose={handleModalClosed} selectedComponent={showModal?.item} onSave={handleComponentSave}/>
      <Card>
        <CardBody>
          <Row>
            <Col md={12} lg={12}>
              <div style={{ maxWidth: '450px', margin: '20px' }}>
                <h2>{p('home')}</h2>
                <p>{p('homeDesc')}</p>
              </div>
            </Col>
            <Col md={8}>
              <Row>
                {renderDragNDrop(sidebar, setSidebar, 'sidebar')}
              </Row>
            </Col>
          </Row>
          <Row>
            <Col md={12} lg={12}>
              <div style={{ maxWidth: '450px', margin: '20px' }}>
                <h2>{p('mainScreen')}</h2>
                <p>{p('mainScreenDesc')}</p>
              </div>
            </Col>
            <Col md={8}>
              <Row>
                {renderDragNDrop(mainScreen, setMainScreen, 'mainScreen')}
              </Row>

            </Col>
          </Row>
          <Row>
            <Col md={12} style={{ marginTop: '15px' }} >
              <Button onClick={handleSubmit} color="primary">{s('save')}</Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};

export default WebsiteLayoutPage;


