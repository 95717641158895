import React, { useState, useEffect } from 'react';
import {
  Col, Card, CardBody,
  Row, Button,
} from 'reactstrap';
import { groupBy, values } from 'ramda';
import TableIcon from 'mdi-react/TableIcon';
import AccountGroupIcon from 'mdi-react/AccountGroupIcon';
import NewspaperIcon from 'mdi-react/NewspaperIcon';
import StadiumIcon from 'mdi-react/StadiumIcon';
import SitemapIcon from 'mdi-react/SitemapIcon';
import CalendarIcon from 'mdi-react/CalendarIcon';
import TicketIcon from 'mdi-react/TicketIcon';
import DragIcon from 'mdi-react/DragIcon';
import WalletMembershipIcon from 'mdi-react/WalletMembershipIcon';
import GavelIcon from 'mdi-react/GavelIcon';
import YoutubeIcon from 'mdi-react/YoutubeIcon';
import BellAlertIcon from 'mdi-react/BellAlertIcon';
import ImageAreaIcon from 'mdi-react/ImageAreaIcon';
import BasketIcon from 'mdi-react/BasketIcon';
import StarCircleIcon from 'mdi-react/StarCircleIcon';
import BrightnessPercentIcon from 'mdi-react/BrightnessPercentIcon';
import ImageOutlineIcon from 'mdi-react/ImageOutlineIcon';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { toast } from 'react-toastify';
import Loading from '../../shared/components/custom/Loading';
import EmptyStateInfo from '../../shared/components/custom/EmptyStateInfo';
import Api from '../../util/api';
import { partial } from '../../util/translation/translation';
import useConfig from '../../util/useConfig';
import Config from '../../config';

const getIconComponent = (type) => {
  switch (true) {
    case type.split('-')[0] === 'spotlight': return ImageAreaIcon;
    case type === 'stats': case type === 'standings': case type === 'playerStats': return TableIcon;
    case type === 'news': return NewspaperIcon;
    case type === 'questionaires': return NewspaperIcon;
    case type === 'players': return AccountGroupIcon;
    case type === 'tierBanner': return WalletMembershipIcon;
    case type === 'matchBanner': case type === 'matchCenter': case type === 'matchcenter': return StadiumIcon;
    case type === 'shop': return BasketIcon;
    case type === 'sponsors': return SitemapIcon;
    case type === 'events': return CalendarIcon;
    case type === 'lottery': case type === 'seasonBanner': case type === 'tickets': return TicketIcon;
    case type === 'offers': return BrightnessPercentIcon;
    case type === 'auctions': return GavelIcon;
    case type === 'youTubeFeed': return YoutubeIcon;
    case type === 'custom': return NewspaperIcon;
    case type === 'seasonMatchNotificationsBanner': return BellAlertIcon;
    case type === 'termsBanner': return BellAlertIcon;
    case type === 'NTFGoalFeed': return BellAlertIcon;
    default: return ImageOutlineIcon;
  }
};

const getPremium = (type) => {
  switch (true) {
    case type === 'youTubeFeed': return true;
    case type === 'custom': return true;
    case type === 'NTFGoalFeed': return true;
    default: return false;
  }
};

const getFeatures = (features, name) => {
  if (features?.includes(name)) {
    return true;
  }
  return false;
};

const AppLayoutPage = () => {
  const config = useConfig();

  const features = config?.featureFlags;
  const [homescreen, setHomescreen] = useState({
    Col0: {
      name: 'Components',
      items: [],
    },
  });
  const [more, setMore] = useState({
    Col4: {
      name: 'More',
      items: [],
    },
  });
  const [tabs, setTabs] = useState({
    Col2: {
      name: 'Tabs',
      items: [],
    },
  });
  const [loadingComponents, setLoadingComponents] = useState(false);

  const p = partial('appLayout');
  const s = partial('shared');

  const orderMapper = (e, i) => {
    if (e.id.includes('spotlight')) {
      const entityId = e.id.split('spotlight-')[1];
      return ({
        id: e.id,
        value: e.id,
        order: i + 1,
        entityId,
      });
    }
    return ({
      id: e.id,
      value: e.id,
      order: i + 1,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = { home: homescreen.Col1.items.map(orderMapper), tabs: tabs.Col1.items.map(orderMapper), more: more.Col1.items.map(orderMapper) };
    if (!Config.monolithEnabled) {
      payload.home = payload?.home?.filter(item => (item?.id !== 'shop' && item?.id !== 'matchBanner'));
      payload.tab = payload?.tab?.filter(item => (item?.id !== 'shop' && item?.id !== 'matchBanner'));
      payload.more = payload?.more?.filter(item => (item?.id !== 'shop' && item?.id !== 'matchBanner'));
    }
    try {
      await Api.app_layout.app.create(config.id, payload);
      toast.success('Layout updated');
    } catch {
      toast.error(s('somethingWentWrong'));
    }
  };
  const getInitialData = async () => {
    setLoadingComponents(true);
    try {
      let getLayout = null;
      const [{ data: layoutOptions }, getLayoutResponse, { data: spotlightGroups }] = await Promise.all([Api.app_layout.app.getComponents(), Api.app_layout.app.all(config.id), Api.spotlight.getGroups({ page: 1, take: 100 }, { order: 'createdAt', direction: 'DESC' })]);
      getLayout = getLayoutResponse;
      if (Object.keys(getLayout.data).length === 0) {
        getLayout = { data: {
          home: [],
          tabs: [],
          more: [],
        } };
      }
      const { home: dbHome, tabs: dbTabs, more: dbMore } = getLayout.data;
      const { home, tabs: _tabs, more: _more } = layoutOptions;
      const homeOptions = home.filter((item) => item !== 'spotlight').map(e => ({ id: e, name: p(e) }));
      spotlightGroups.forEach((group) => homeOptions.push({
        id: `spotlight-${group.id}`,
        name: `${group.title}`,
      }));
      const homeObject = {
        Col0: {
          name: 'Components',
          items: homeOptions.filter(e => !dbHome.some(h => h.value === e.id)),
        },
        Col1: {
          name: 'Homescreen',
          items: dbHome.map(e => {
              if (e.value.includes('spotlight')) {
                const spotId = e.value.split('spotlight-')[1];
                const foundSpotlight = spotlightGroups.find((item) => item.id === spotId);
                if (!foundSpotlight) {
                  return null;
                }
                return ({ id: e.value, name: `${p('spotlight')} ${foundSpotlight.title}` });
              }
              return ({ id: e.value, name: p(e.value) });
            }).filter((item) => !!item),
        },
      };
      const tabsObject = {
        Col0: {
          name: 'Tab alternativ',
          items: _tabs.filter(e => !dbTabs.some(h => h.value === e)).map(e => ({ id: e, name: p(e) })),
        },
        Col1: {
          name: 'Tabs in-app',
          items: dbTabs.map(e => ({ id: e.value, name: p(e.value) })),
        },
      };
      const moreObject = {
        Col0: {
          name: 'More alternativ',
          items: _more.filter(e => !dbMore.some(h => h.value === e)).map(e => ({ id: e, name: p(e) })),
        },
        Col1: {
          name: 'More screen in-app',
          items: dbMore.map(e => ({ id: e.value, name: p(e.value) })),
        },
      };
      setHomescreen(homeObject);
      setTabs(tabsObject);
      setMore(moreObject);
      setLoadingComponents(false);
    } catch (err) {
      console.log(err);
      toast.error(err);
      setLoadingComponents(false);
    }
  };
  useEffect(() => {
    getInitialData();
  }, []);

  const onDragEnd = async (result, columns, setColumns, dropfieldName) => {
    if (!result.destination) return;
    const { source, destination } = result;
    if (dropfieldName === 'tabs' && destination.droppableId === 'Col1' && columns[destination.droppableId].items.length >= 4) {
      toast.error('Too many items');
      return;
    }
    let columnObject = {};
    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);
      columnObject = {
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems,
        },
      };
      setColumns(columnObject);
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      columnObject = {
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems,
        },
      };
      setColumns(columnObject);
    }
  };
  const renderDragNDrop = (columns, setColumns, dropfieldName) => (
    <DragDropContext
      onDragEnd={result => onDragEnd(result, columns, setColumns, dropfieldName)}
    >

      {Object.entries(columns).map(([columnId, column]) => {
        return (
          <Col
            key={column.name}
            xs={6}
            sm={6}
            md={6}
            xl={6}
          >
            <strong>{column.name.split(' ').pop() === 'in-app' || column.name === 'Homescreen' ? s('Active') : s('Available')}</strong>
            <Droppable droppableId={columnId} key={columnId}>
              {(provided, snapshot) => {
                return (
                  <div
                    className="comCol"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={{
                      background: snapshot.isDraggingOver
                        ? '#d9edff'
                        : '#F9FAFB',
                      borderWidth: 1,
                      borderRadius: 3,
                      borderStyle: 'solid',
                      borderColor: snapshot.isDraggingOver ? '#0469DC' : '#D7DAE0',
                    }}
                  >
                    {column.items.length === 0 &&
                      <div className="my-5">
                        <EmptyStateInfo icon="layout" title={s('emptyStateTitle')} text={column.name.split(' ').pop() === 'in-app' ? p('emptyAppComponents') : ''}/>
                      </div>
                    }
                    {column.items.map((item, index) => {
                      const IconComponent = getIconComponent(item.id);
                      const last = column.items.length === index + 1 ? 0 : '1px dashed #D7DAE0';
                      if (!Config.monolithEnabled && (item?.id === 'shop' || item?.id === 'matchBanner')) {
                        return (<div/>);
                      }
                      const disabled = (getPremium(item?.id) ? !getFeatures(features, item?.id) : false);
                      return (
                        <Draggable
                          key={item.id}
                          draggableId={`${item.id}${columnId}`}
                          index={index}
                          isDragDisabled={disabled}
                        >
                          {(provided2, snapshot2) => {
                            return (
                              <div
                                className=""
                                ref={provided2.innerRef}
                                {...provided2.draggableProps}
                                {...provided2.dragHandleProps}
                                style={{
                                  opacity: disabled ? 0.4 : 1,
                                  backgroundColor: snapshot2.isDragging
                                  ? '#F5FAFF'
                                  : 'white',
                                color: snapshot2.isDragging
                                ? '#0469DC !important'
                                : 'black',
                                padding: 10,
                                borderTop: snapshot2.isDragging
                                ? '1px solid #0469DC'
                                : 0,
                                borderLeft: snapshot2.isDragging
                                ? '1px solid #0469DC'
                                : 0,
                                borderRight: snapshot2.isDragging
                                ? '1px solid #0469DC'
                                : 0,
                                borderBottom: snapshot2.isDragging
                                ? '1px solid #0469DC'
                                : last,
                                  ...provided2.draggableProps.style,
                                }}
                              >
                                <div style={{ margin: '0 auto', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                  <DragIcon style={{ widht: 30, color: '#D7DAE0' }} className="mt-1"/>
                                  <IconComponent style={{ margin: '5px 5px 0', padding: 3, widht: 10, borderRadius: 6, fill: snapshot2.isDragging ? '#0469DC' : '#121B2B', backgroundColor: '#D7E9FE' }} size={30} />
                                  {/* getPremium(item.id) */ false && <StarCircleIcon style={{ position: 'relative', right: 11, top: -11, color: '#703FF3', width: 12 }}/>}
                                  <p style={{ marginTop: 5, fontSize: 12, fontWeight: 700, lineHeight: '12px', color: snapshot2.isDragging ? '#0469DC' : 'black' }}>{item.name}</p>
                                </div>
                              </div>
                            );
                          }}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </div>
                );
              }}
            </Droppable>
          </Col>
        );
      })}
    </DragDropContext>
  );
  return (
    <form className="form" onSubmit={handleSubmit}>
      <Loading loading={loadingComponents} />
      <Card>
        <CardBody>
          <Row>
            <Col md={12} lg={12}>
              <div style={{ maxWidth: '450px', margin: '20px' }}>
                <h2>{p('home')}</h2>
                <p>{p('homeDesc')}</p>
              </div>
            </Col>
            <Col md={8}>
              <Row>
                {renderDragNDrop(homescreen, setHomescreen, 'homescreen')}
              </Row>
              <div className="rowDistance">
                <div style={{ maxWidth: '450px', marginBottom: '50px' }}>
                  <h2>{p('bottomMenu')}</h2>
                  <p>{p('bottomMenuDesc')}</p>
                </div>
                <Row>
                  {renderDragNDrop(tabs, setTabs, 'tabs')}
                </Row>
              </div>
            </Col>
            <div className="app-t" style={{ marginTop: 21 }}>
              <AppMock home={homescreen?.Col1?.items} tabs={tabs.Col1?.items} />
            </div>
          </Row>

          <hr/>

          <Row>
            <Row>
              <Col>
                <div style={{ maxWidth: '450px', margin: '20px' }}>
                  <h2>{p('more')}</h2>
                  <p>{p('moreDesc')}</p>
                </div>
              </Col>
            </Row>
            <Col md={8}>
              <div className="rowDistance">
                <Row>
                  {renderDragNDrop(more, setMore, 'more')}
                </Row>
              </div>
            </Col>
            <div className="app-t rowDistance">
              <AppMock more={more.Col1?.items} tabs={tabs.Col1?.items} />
            </div>
            <Col md={12} style={{ marginTop: '15px' }} >
              <Button onClick={handleSubmit} color="primary">Save</Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </form>
  );
};

export default AppLayoutPage;
const AppMock = ({ home = [], tabs = [], more = [] }) => {
  const p = partial('appLayout');
  const components = (component) => {
    switch (component) {
      case 'tickets': return {
        name: p('tickets'),
        icon: 'Ticket',
      };
      case 'matchcenter': return {
        name: p('matchcenter'),
        icon: 'calendar-outline',
      };
      case 'players': return {
        name: p('players'),
        icon: 'hockey-helmet',
      };
      case 'shop': return {
        name: p('shop'),
        icon: 'shopping-cart',
      };
      case 'events': return {
        name: p('events'),
        icon: 'calendar',
      };
      case 'lottery': return {
        name: p('lottery'),
        icon: 'gambling',
      };
      case 'auctions': return {
        name: p('auctions'),
        icon: 'hammer',
      };
      case 'offers': return {
        name: p('offers'),
        icon: 'Offers',
      };
      case 'stats': return {
        name: p('stats'),
        icon: 'stats',
      };
      case 'sponsors': return {
        name: p('sponsors'),
        icon: 'user',
      };
      case 'tierBanner': return {
        name: p('tierBanner'),
        icon: 'user',
      };
      case 'questionaires': return {
        name: p('questionaires'),
        icon: 'calendar',
      };
      case 'custom': return {
        name: p('custom'),
        icon: 'link',
      };
      case 'youTubeFeed': return {
        name: p('youTubeFeed'),
        icon: 'youtube',
      };
      case 'news': return {
        name: p('news'),
        icon: 'news',
      };
      default: return {
        name: component,
        icon: 'box',
      };
    }
  };
  const groups = [
    ['tierBanner'],
    ['news', 'youTubeFeed', 'custom'],
    ['auctions', 'lottery', 'questionaires'],
    ['sponsors', 'offers', 'events'],
    ['players', 'stats', 'matchcenter'],
  ];
  const groupdMore = values(groupBy((e) => groups.findIndex((group) => group.includes(e.id)), more));
  return (
    <div className="temp" style={{ border: '3px solid black', borderRadius: 6 }}>
      <div className="templateApp" >
        <div className="header"><img src="/assets/files/homepage/club-topbar-logo.png" alt=""/></div>
        {home.map(v => {
          if (v.id.includes('spotlight') || v.id.includes('youTubeFeed') || v.id.includes('NTFGoalFeed') || v.id.includes('custom')) {
            return (
              <div key={v.id}>
                <p style={{ backgroundColor: 'white', fontSize: 13, fontWeight: 'bold', paddingLeft: 10, color: 'black', paddingTop: 7 }}>{v.name}</p>
                <img alt={v.id} src="/assets/files/homepage/new-spotlight-homescreen.png" />
              </div>);
          }
          return <img alt={v.id} key={v.id} src={`/assets/files/homepage/${v.id}-homescreen.png`} />;
        })}
        {groupdMore.map((group) => (
          <div className="tempGroup" key={group[0].id}>
            {group.map((item) => (
              <div className="tempGroupItem" key={item.id}>
                <div className="tempGroupItemIconText">
                  <span className={`qricon-${components(item.id)?.icon || 'calendar'}`} />
                  <span>{components(item.id).name}</span>
                </div>
                <div className="tempGroupItemRight">
                  <span className="sidebar__link-icon icon-chevron-right" />
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
      <div className="templateTab first">
        <div className="templateTabElement">
          <span className="qricon-Homea fontSize-20" />
        </div>
        {tabs.map(v => (
          <div className="templateTabElement" key={v.id}>
            <span className={`qricon-${components(v.id).icon} fontSize-20`} />
          </div>))}
        <div className="templateTabElement">
          <span className="qricon-menu-circle1 fontSize-20" />
        </div>
      </div>
    </div>
  );
};

