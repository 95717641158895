import React, { useMemo } from 'react';
import { Col, Button, Container, Card } from 'reactstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Line } from 'react-chartjs-2';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import t, { partial } from '../../util/translation/translation';
import { getBaseDateTime } from '../../util/date-and-time';

const PushData = () => {
  const { state: messageState } = useLocation();
  const { success_count, opened_count, openedPercentage, labels } = messageState.mappedPushStat;
  const chartData = useMemo(() => {
    return {
      labels: labels.map(label => moment(label.date).format('DD.MM. HH')),
      datasets: [
        {
          label: 'Opened',
          data: labels.map(ds => ds.views),
          backgroundColor: '#87BEFD',
          borderColor: '#0469DC',
        },
      ],
    };
  }, [labels]);
  const s = partial('shared');

  return (
    <Container>
      <Card>
        <div className="flex space-between">
          <h3 className="page-title mb-2">{s('Statistics')}</h3>
          <Link to="/messages?tab=2"><Button size="sm" color="secondary">{s('close')}</Button></Link>
        </div>
        <Col lg={8} className="p-1">
          <div className="table-box box-shadow mb-1">
            <Line data={chartData} />
          </div>
          <div className="no-background">
            <strong>{t('shared.sent')}: </strong><span>{getBaseDateTime(messageState?.createdAt)} </span>
            <strong>{t('InsightPage.sent')}: </strong><span>{success_count} </span>
            <strong>{t('InsightPage.open')}: </strong><span>{opened_count} ({`${openedPercentage}%`}) </span>
          </div>
        </Col>
      </Card>
    </Container>
  );
};


export default PushData;
