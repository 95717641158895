import { partial } from '../../util/translation/translation';

const validate = (values) => {
  const p = partial('validateErrors');
  const errors = {};
  if (!values.selectedEntity) {
    errors.selectedEntity = p('fieldEmpty');
  }

  return errors;
};
export default validate;
